.hero {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.left-h {
  padding: 2rem;

  flex: 2.5;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.right-h {
  flex: 1;
  position: relative;
  background-color: var(--green);
}

.the-best-ad {
  margin-top: 4rem;
  background-color: #d8cacb;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: #f8f6f6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.the-best-ad > span {
  z-index: 2;
}
.the-best-ad > div {
  position: absolute;
  background-color: var(--green);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: var(--green);
  text-overflow: inherit;
}
.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.right-h > .btn {
  position: absolute;
  list-style: none;
  top: 2rem;
  color: whitesmoke;
  border-radius: 10px;
  padding: 10px 50px;
  right: 3rem;
}

/* Define the keyframes animation */
@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5px, 10px);
  }
  100% {
    transform: translate(5px, 20px);
  }
}
.growth > img {
  width: 2rem;
}
/* Apply the animation to the .growth element */
.growth {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #d8cacb;
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 10rem;
  top: 12rem;

  /* Apply the animation */
  animation: move 3s ease-in-out infinite alternate-reverse;
}

.growth > div > span:nth-of-type(1) {
  display: block; /* or inline-block, depending on your desired layout */
  color: gray;
}

.growth > div > span:nth-of-type(2) {
  color: white;
  padding-top: 2px; /* Padding cannot be negative, use margin for spacing */
  font-size: 1.25rem;
}

.hero-img-back {
  position: absolute;
  top: 22rem;
  right: 0.1rem;
  width: 756px;
  height: 600px;
  margin: 0;
}

.hero-img {
  position: relative;
  width: 25rem;
  margin-top: 11rem;
  margin-right: 11rem;

  z-index: 10;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
  }
  .the-best-ad {
    margin-top: 0;
    font-size: 10.2px;
    align-self: center;
    transform: scale(0.9);
  }
  .hero-text {
    font-size: x-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text > div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .right-h {
    position: relative;
    background: none;
  }
  .growth {
    left: 12rem;
    top: 2.4rem;
    width: 3rem;
    height: 3rem;
    padding: 15px;
    padding-left: 9px;
    padding-top: 9px;
  }
  .growth > img {
    padding-bottom: 0;
    width: 1.2rem;
  }
  .growth > div {
    font-size: 6.5px;
  }
  .growth > div > span:nth-child(2) {
    font-size: 13px;
  }
  .hero-img {
    position: relative;
    width: 15rem;
    left: 2rem;
    top: -9rem;
    align-self: center;
  }
  .hero-img-back {
    width: 26rem;
    height: 20rem;
    top: 7.66rem;
    right: -2.7rem;
  }
  .the-best-ad > div {
    width: 3.6rem;
  }
}
