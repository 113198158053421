.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 26rem;
  height: 6rem;
}
.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: #bab578;
}

.header-menu > li:hover {
  color: whitesmoke; /* or use your var(white) if defined correctly */
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 26rem;
    height: 6rem;
    padding-left: 4rem;
  }
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }

  .header-menu {
    flex-direction: column;
    background-color: var(--green);
    font-size: small;
    padding: 1.2rem;
    color: var(--cream);
  }
}
