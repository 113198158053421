
:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor:  #3c3f45;
  --green:  #BAB578;
  --cream: #E5DCDD;
  --footerText: #c4a844;

}

.App {
  mix-blend-mode: overlay;
  background-color: var(--cream);
  display: flex;
  flex-direction: column;
   
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}

.stroke-text{
  color:transparent;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color:var(--green)

}

.stroke-text1{
  color:transparent;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color:var(--cream)

}

.btn{
  background-color: #d8cacb;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover{
  cursor: pointer;
}

.form{
  background-color: var(--green);
}

