.form {
    background-color: var(--green);
    display: flex;
    gap: 0rem;
    padding: 0 2rem;
  }

.left-half{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: var(--cream);   
}

.left-half>span:nth-child(1){
    font-size: 3em ;
    font-style: italic;
    font-weight: bold;
    padding-top: 4rem;
}
  
.left-half>span:nth-child(2){
    font-size: 3em ;
    font-style: italic;
    font-weight: bold;    
}

.left-half>span:nth-child(3){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

  .right-half{
    padding-top: 2rem;
    flex: 1;
    position: relative;
  }
  /* Style for the input fields and button */
  .input-group {
    margin-bottom: 1rem;
    
  }


  
  .custom-input {
    width: 100%; /* Adjust the width to fill the container */
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    background-color: #e8e1e1;
    border-radius: 0.25rem;
    outline: none;
  }
 
  
  
  .custom-input::placeholder {
    color: #888;
  }
  
  .custom-button {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #cebdbf;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .custom-button:hover {
    background-color: var(--cream);
  }
  
  /* Adjust the CSS for the form-container within the right half */
  .right-half .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px; /* Increase the maximum width as needed */
    margin: 0 auto; /* Center the container horizontally */
    height: 400px; /* Adjust the height as needed */
   
  }
  
  /* Add this to your ContactForm.css */
.success-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .success-popup p {
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  @media screen and (max-width :768px){
    .form{
      flex-direction: column;
      padding: 0 1rem;
    }
    .left-half{ 
      gap: 1rem;   
    }
    .left-half>span:nth-child(1){
      font-size: 2em ;
      text-align: center;
      font-style: italic;
      font-weight: bold;
      padding-top: 3rem;
    }
    .left-half>span:nth-child(2){
      font-size: 2em ;
      font-style: italic;
      font-weight: bold; 
      text-align: center;
    }
    .left-half>span:nth-child(3){
      padding-top: 1rem;
      text-transform: none;
      text-align: justify;
      letter-spacing: 0.5px;
      line-height: 25px;
      font-size: 16px;
      text-align: center;
    }
    .custom-input {
      
      width: 80%; /* Adjust the width to fill the container */
      padding: 0.7rem;
      padding-bottom: 1rem;
      padding-right: 1rem;
      font-size: 12px;
      border-radius: .5rem;
      
    }
    .input-group {
      margin-bottom: .8rem;  
    }
    .right-half{
      padding-top: 2rem;
      padding-bottom: 2rem;
      
      
    }
    .right-half .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px; 
      margin: 0 auto;
      height: 300px; 
      text-align: center; 
    }
    .custom-button {
      padding: 0.75rem;
      margin: 0 auto;
      font-size: 14px;
    }

  }